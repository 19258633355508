/*color*/
$white: #ffffff;
$black: #000000;
$color: #ad79f7;
$grey: #8c8c8c;
$pink: #f3ebff;

@mixin trans1 {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

* {
  margin: 0;
  padding: 0;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

.file-uploader-pnl {
  img {
    margin-top: 15px;
  }
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: $color;
  height: auto;
}

body,
html,
main {
  -webkit-font-smoothing: antialiased;
}

a,
a:focus,
button,
button:focus {
  outline: 0 !important;
  box-shadow: none;
}

a {
  display: inline-block;
  text-decoration: none !important;
}

.bg-layer {
  opacity: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.bg-layer,
iframe,
video,
source {
  height: 100%;
  width: 100%;
}

img,
picture,
svg {
  max-width: 100%;
}

.full-div {
  display: inline-block;
  width: 100%;
}

.half-div {
  display: inline-block;
  width: 50%;
  float: left;
}

.txt-right,
.text-right {
  text-align: right;
}

.txt-center,
.txt-centr,
.text-center {
  text-align: center;
}

.txt-left,
.text-left {
  text-align: left;
}

.flex-div {
  display: flex;
  justify-content: space-between;
}

.flex-div h6 {
  max-height: 16px;
}

.flex-div-sm {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: inline-block;
    text-align: center;
  }
}

.flex-div-xs {
  display: flex;
  justify-content: space-between;

  @media (max-width: 575px) {
    display: inline-block;
    text-align: center;
  }
}

body,
html {
  min-height: 100%;
  height: auto;
}

img {
  max-width: 100%;
}

div,
img,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
span {
  @include trans1();
}

ul {
  padding-left: 0;
}

.pd-r-0 {
  padding-right: 0;
}

.pd-l-0 {
  padding-left: 0;
}

body {
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1300px;
  }
}

.shery {
  animation: myfirst 5s linear 2s infinite alternate;
}

@keyframes myfirst {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }

  25% {
    background-color: yellow;
    left: 200px;
    top: 0px;
  }

  50% {
    background-color: blue;
    left: 200px;
    top: 200px;
  }

  75% {
    background-color: green;
    left: 0px;
    top: 200px;
  }

  100% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
}

/**** divider ***/
.spacer-10 {
  width: 100%;
  height: 10px;
  display: inline-block;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: inline-block;
}

.spacer-30 {
  width: 100%;
  height: 30px;
  display: inline-block;
}

.spacer-40 {
  width: 100%;
  height: 40px;
  display: inline-block;
}

.spacer-50 {
  width: 100%;
  height: 50px;
  display: inline-block;
}

.spacer-60 {
  width: 100%;
  height: 60px;
  display: inline-block;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 30px;
}

button:focus {
  box-shadow: none !important;
}

::placeholder {
  color: rgba(140, 140, 140, 0.5) !important;
  font-weight: 700;
  font-size: 14px;
}

:-ms-input-placeholder {
  color: rgba(140, 140, 140, 0.5) !important;
  font-weight: 700;
  font-size: 14px;
}

::-ms-input-placeholder {
  color: rgba(140, 140, 140, 0.5) !important;
  font-weight: 700;
  font-size: 14px;
}

input,
input.form-control,
select,
select.form-control,
textarea,
textarea.form-control {
  background-color: transparent;
  height: 50px;
  font-size: 14px;
  background-color: $white;

  &:focus {
    box-shadow: none;
    border-color: $color;
  }
}

select,
select.form-control {
  color: rgba(140, 140, 140, 0.5);
  font-weight: 700;
  font-size: 14px;
}

textarea,
textarea.form-control {
  min-height: 100px;
  max-height: 100px;
  min-width: 100%;
  max-width: 100%;
}

input.submit-btn,
.submit-btn {
  width: 100%;
  background-color: $color;
  margin-bottom: 20px;
  line-height: 45px;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-block;
  border: none;
  text-align: center;
  color: $white;
  font-weight: 700;
  font-size: 18px;
  @include trans1();
  border-radius: 3px;

  &:focus,
  &:hover {
    background-color: $color;
    color: $white;
    box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6);
  }
  &:active {
    background-color: rgba(173, 121, 247, 0.6) !important;
    color: $white;
    box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6) !important;
    border-color: $color !important;
  }
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../src/assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "SF Pro Display";
  font-weight: 400;
  font-size: 16px;
}

main {
  display: inline-block;
  width: 100%;
  position: relative;
}

.simple-anchor {
  color: $grey;
  font-size: 16px;

  &:hover {
    color: $color;
  }
}

.label-container {
  height: 50px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.label-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: -20px;
}

// Reguler Button
.reg-btn-disabled {
  background-color: #404e5d !important;
  border-color: #404e5d !important;
}
.no-property {
  margin-left: 16px;
}
.reg-btn {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 25px;
  border: 1px solid transparent;
  background-color: $color;
  color: black;
  font-size: 14px;
  text-align: center;
  font-weight: 700;

  &.fill,
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(173, 121, 247, 0.6) !important;
    color: $white !important;
    box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6) !important;
    border-color: $color !important;
  }
  #create-btn:active {
    background-color: transparent;
    color: #ad79f7;
    border-color: #ad79f7;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: $color !important;
    border-color: $color !important;
  }

  &.big {
    padding: 13px 25px;
    font-size: 16px;
    min-width: 280px;

    @media (max-width: 575px) {
      min-width: 90%;
    }
  }

  &.trans {
    background-color: transparent;
    border-color: $color;
    color: $color;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $color;
    }
  }

  &.br-rad {
    border-radius: 3px;
    padding: 15px 15px;
  }
}
.reg-btn-notification {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 25px;
  border: 1px solid transparent;
  background-color: $color;
  color: black;
  font-size: 14px;
  text-align: center;
  font-weight: 700;

  &.fill,
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(173, 121, 247, 0.6) !important;
    color: $white;
    box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6) !important;
    border-color: $color !important;
  }
  #create-btn:active {
    background-color: transparent;
    color: #ad79f7;
    border-color: #ad79f7;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: $color !important;
    border-color: $color !important;
  }

  &.big {
    padding: 13px 25px;
    font-size: 16px;
    min-width: 280px;

    @media (max-width: 575px) {
      min-width: 90%;
    }
  }

  &.trans {
    background-color: transparent;
    border-color: $color;
    color: $color;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $color;
    }
  }

  &.br-rad {
    border-radius: 3px;
    padding: 15px 15px;
  }
}

// Reguler Button

.half-list {
  display: inline-block;
  width: 100%;

  li {
    width: 50%;
    float: left;
    padding: 0 15px 0 0;
    display: inline-block;
    margin-bottom: 15px;

    @media (max-width: 575px) {
      width: 100%;
      padding-right: 0;
    }

    @media (max-width: 767px) {
      .reg-btn.big {
        min-width: 100%;
      }
    }
  }
}

// BreadCrum
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin: 0;

  .breadcrumb-item {
    color: $black;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;

    a {
      color: $color;
      font-size: 18px;
      font-weight: 700;
      margin: 0;

      &:hover {
        color: $color;
      }
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    display: inline-block;
    width: 100%;
    text-align: center;

    .breadcrumb-item {
      display: inline-block;
    }
  }
}

// Pagination
.pagination-container {
  text-transform: center;
  display: inline-block;
  width: 100%;

  .custom-root {
    display: inline-block;

    ul {
      display: inline-block;
      text-align: center;

      li {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }
}

.pagination {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0;

  li {
    display: inline-block;
    margin: 0 7px;
    padding: 0;

    a {
      padding: 0;
      font-size: 16px;
      color: $white;

      &:hover {
        color: $color;
      }
    }
  }
}

// Pagination

.white-div {
  background-color: $white;
  padding-bottom: 30px;
}

/* Header Starts Here
===================== */
.search-pnl {
  input {
    border-color: $color;
    border-radius: 25px;
    height: 40px;
    background-color: $pink;
    background-image: url(../src/assets/images/icons/icon-search.png);
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 30px;
  }
}

.search-pnl input[type="search"]:focus {
  background-color: $pink;
  // outline: none;
}

// .breadcrumb-item+.breadcrumb-item::before {
//   // float: left;
//   // padding-right: 0.5rem;
//   color: $pink !important ;
//   // content: "/";
// }

.nav-user-list {
  display: flex;
  list-style: none;
  margin: 0;

  li {
    margin-left: 15px;

    a:not(.logout) {
      display: inline-block;
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $pink;
      color: $color;
      border-radius: 50%;
      font-size: 18px;

      &:hover {
        background-color: $color;
        color: $white;
      }
    }

    &.web-view {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  h1 {
    font-size: 15px;
    color: $black;
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
  }

  .img-pnl {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }

  .user-info {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid $color;
    padding-right: 15px;

    .img-pnl {
      margin-right: 10px;
    }
  }
}

/* Header Ends Here
=================== */

/* Dashboard Starts Here
======================== */
.dasboard-main-banner{
  width: 100%;
  min-height: 100vh;
  // padding-left: 300px;
}
.dashboard-inner-pnl-banner {
  display: inline-block;
  min-height: 100vh;
  width: 100%;
}
.head-inner-panel-banner {
  display: inline-block;
  width: 100%;
  padding: 30px 30px 0;
  background-color: #f6f6f6;
  min-height: calc(100vh - 69px);

  @media (max-width: 991px) {
    padding: 30px 15px 0;
  }
  @media (max-width: 767px) {
    text-align: center;
  }

  @media (max-width: 575px) {
    padding: 20px 15px 0px;
  }

  h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;

    @media (max-width: 1200px) {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }

    @media (max-width: 575px) {
      font-size: 20px;
    }
  }

  label {
    color: $grey;
    font-size: 14px;
    font-weight: 700;
  }
}
.full-div-banner {
  display: inline-block;
  width: 100%;
  margin-left: 30px;
}
.dasboard-main-banner.head-info {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: end;
  border-bottom: 2px solid #f0f0f0;
  padding: 15px 30px 12px;

  @media (max-width: 575px) {
    padding: 15px 15px 12px;
  }

  .search-pnl {
    width: 400px;
  }
}


.dasboard-main {
  width: 100%;
  min-height: 100vh;
  padding-left: 300px;

  @media (max-width: 991px) {
    padding-left: 60px;
  }

  .dashboard-inner-pnl {
    display: inline-block;
    min-height: 100vh;
    width: 100%;
  }

  .head-info {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: end;
    border-bottom: 2px solid #f0f0f0;
    padding: 15px 30px 12px;

    @media (max-width: 575px) {
      padding: 15px 15px 12px;
    }

    .search-pnl {
      width: 400px;
    }
  }

  .head-inner-panel {
    display: inline-block;
    width: 100%;
    padding: 30px 30px 0;
    background-color: #f6f6f6;
    min-height: calc(100vh - 69px);

    @media (max-width: 991px) {
      padding: 30px 15px 0;
    }
    @media (max-width: 767px) {
      text-align: center;
    }

    @media (max-width: 575px) {
      padding: 20px 15px 0px;
    }

    h1 {
      font-size: 30px;
      font-weight: 700;
      margin: 0;

      @media (max-width: 1200px) {
        font-size: 20px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
      }

      @media (max-width: 575px) {
        font-size: 20px;
      }
    }

    label {
      color: $grey;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.btn-lister {
  display: inline-block;

  @media (max-width: 1400px) {
    max-width: calc(100% - 200px);
    margin: 0;
    text-align: right;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    margin-top: 15px;
    text-align: center;
  }

  li {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;

    @media (max-width: 1400px) {
      margin-bottom: 20px;
    }

    @media (max-width: 767px) {
      margin: 0 0 20px;
      padding: 0 7px;
      max-width: 100%;
    }

    @media (max-width: 575px) {
      width: 100%;
    }

    .search-pnl {
      width: 300px;

      @media (max-width: 1400px) {
        width: 250px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

// DropDown Button
.dropdown {
  button {
    background-color: transparent !important;
    color: $color !important;
    font-weight: 700;
    border: none;
    font-size: 22px;
    padding: 0;

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    min-width: 85px;

    a {
      padding: 7px 10px;
      font-weight: 700;
      font-size: 14px;
      color: $color;
      margin: 0;

      &:active,
      &:hover {
        background-color: $color;
        color: $white;
      }
    }
  }
}

// DropDown Button

// Table
.table-container {
  display: inline-block;
  width: 100%;

  .table-container-inner {
    display: inline-block;
    width: 100%;

    @media (max-width: 1200px) {
      overflow-x: scroll;

      table {
        width: 900px;
      }
    }
  }
}

.error-msg {
  color: red;
}

.dropdown .dropdown-menu {
  padding: 0;
  min-width: 110px;
  width: 100%;
}

table {
  border-bottom: 1px solid$pink;

  thead th {
    background-color: $pink;
    color: $color;
    font-weight: 700;
    border: none;
    border: none !important;
  }

  tbody td {
    color: $grey;
    font-size: 14px;
    font-weight: 700;
    background-color: $white;
    border-color: $pink;

    img {
      margin-right: 10px;
    }

    a {
      margin: 0 4px;
    }

    .dropdown {
      position: relative;
      top: -10px;
    }
  }
}

// Table

// Collection Page
.collection-container {
  display: inline-block;
  width: 100%;
  max-width: 1400px;
  background-color: $white;
  padding: 30px 15px 50px;
  border-radius: 10px;

  @media (max-width: 575px) {
    padding: 20px 10px 30px;
  }
}

.collection-list {
  display: inline-block;
  width: 100%;

  @media (max-width: 767px) {
    text-align: center;
  }

  > li {
    display: inline-block;
    width: 33.33333%;
    float: left;
    padding: 0 15px;

    @media (max-width: 1200px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 450px;
      margin: 0 auto 0px;
      float: none;
    }

    @media (max-width: 575px) {
      max-width: 280px;
    }
  }

  &.small {
    > li {
      width: 16.66667%;

      @media (max-width: 1600px) {
        width: 25%;
      }

      @media (max-width: 1400px) {
        width: 33.33333%;
      }

      @media (max-width: 1200px) {
        width: 50%;
      }

      @media (max-width: 991px) {
        width: 50%;
      }

      @media (max-width: 767px) {
        width: 50%;
      }

      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}

// Collection Post
.collection-post {
  display: inline-block;
  width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: $pink;
  margin-bottom: 30px;
  text-align: center;
  min-height: 270px;
  @include trans1();

  &:hover {
    box-shadow: 10px 10px 10px rgba(173, 121, 247, 0.6);
  }

  .banner-pnl {
    display: inline-block;
    width: 100%;
    border-radius: 4px 4px 0 0;
    height: 110px;
    position: relative;
    position: relative;

    .bg-layer {
      opacity: 0.7;
      border-radius: 4px 4px 0 0;
    }

    .post-menu {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 2;
      background-color: $pink;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $pink;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $color;

        button {
          color: $white !important;
        }
      }
    }
  }

  .txt-pnl {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 30px 15px 10px;

    .img-pnl {
      position: absolute;
      width: 100%;
      left: 0;
      top: -30px;
      text-align: center;

      .img-pnl-inner {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
    color: $black;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
    color: $black;

    span {
      color: $color;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    color: $black;

    span {
      font-weight: 400;
      font-size: 14px;
      display: inline-block;
      width: 100%;
    }
  }

  &.small {
    text-align: initial;

    .txt-pnl {
      padding: 0 10px 10px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      color: $grey;
      font-size: 14px;
      display: inline-block;
      width: 100%;
      margin-top: 0px;
    }

    h6 {
      font-size: 14px;
      color: $black;
      font-weight: 700;
    }
  }
}

// COllection Post

// Collection Detail Panel
.collection-detail-container {
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;

  > .img-pnl {
    width: 350px;
    position: relative;
    display: inline-block;
    float: left;

    @media (max-width: 1400px) {
      width: 300px;
    }

    @media (max-width: 1200px) {
      float: none;
      width: 100%;
      margin: 0 auto 20px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    img {
      min-height: 150px;
      border-radius: 3px 3px 0 0;
    }

    .like-link {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 2;
      font-size: 18px;
      color: $black;
      background-color: $white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 575px) {
        position: relative;
        right: unset;
        top: 0;
      }

      &:hover {
        background-color: $color;
        color: $white;
      }
    }

    .timer-pnl {
      position: absolute;
      left: 7px;
      top: 7px;
      background-color: $color;
      z-index: 2;
      padding: 5px 10px;
      border-radius: 3px;
      @include trans1();
      color: $white;

      @media (max-width: 575px) {
        top: 10px;
        right: unset;
        left: 10px;
      }

      &:hover {
        background-color: $color;
        color: $white;
      }
    }
    .heart-pnl {
      position: absolute;
      right: 7px;
      top: 7px;
      background-color: $color;
      z-index: 2;
      padding: 5px 10px;
      border-radius: 3px;
      @include trans1();
      color: $white;

      @media (max-width: 575px) {
        top: 10px;
        left: unset;
        right: 10px;
      }

      &:hover {
        background-color: $color;
        color: $white;
      }
    }
    .heart-pnl-active {
      position: absolute;
      top: 48px;
      left: 25px;
      background-color: $color;
      z-index: 2;
      padding: 5px 10px;
      border-radius: 3px;
      @include trans1();
      color: $white;

      @media (max-width: 1200px) {
        top: 48px;
        left: 25px;
        right: unset;
      }
      @media (max-width: 575px) {
        top: 68px;
      }

      &:hover {
        background-color: $color;
        color: $white;
      }
    }

    .price-panel {
      position: static;
      background-color: $color;
      border: 1px solid rgba(173, 121, 247, 0.6);
      z-index: 2;
      padding: 15px 15px;
      border-radius: 0 0 3px 3px;
      @include trans1();

      p {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .txt-pnl {
    width: calc(100% - 350px);
    padding-left: 15px;
    float: left;
    display: inline-block;

    @media (max-width: 1400px) {
      width: calc(100% - 300px);
    }

    @media (max-width: 1200px) {
      width: 100%;
      float: none;
    }

    h2 {
      color: $black;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        font-size: 28px;
      }

      @media (max-width: 575px) {
        font-size: 26px;
      }
    }

    h3 {
      color: $grey;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    h4 {
      color: $black;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
}

// Collection Detail Panel

// Creator Post
.creator-post {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(243, 235, 255, 0.5);
  border-radius: 25px;
  padding: 15px 15px;
  text-align: initial;
  margin-bottom: 30px;
  @include trans1();

  &:hover {
    background-color: $pink;
  }

  @media (max-width: 575px) {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .creator-post-inner {
    display: flex;

    @media (max-width: 575px) {
      display: inline-block;
      width: 100%;
    }

    .img-pnl {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .txt-pnl {
      width: calc(100% - 40px);
      padding-left: 10px;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: 700;
    color: $black;
    margin: 3px 0 0;
  }

  p {
    font-size: 15px;
    color: $black;
    font-weight: 600;
    margin: 0;
  }
}

/* Accordian */
.accordion {
  text-align: initial;
  margin-bottom: 50px;

  .accordion-item {
    border-bottom: 2px solid $pink;

    h2.accordion-header {
      margin: 0;

      button {
        display: inline-block;
        width: 100%;
        background-color: transparent;
        border: none;
        text-align: initial;
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        color: $color;
        @include trans1();
        position: relative;

        &:hover {
          color: $color;
        }

        i {
          position: absolute;
          right: 15px;
          font-size: 20px;
          font-weight: 700;
          transform: rotate(-180deg);
          @include trans1();
        }

        &.accordion-button.collapsed {
          color: $black;

          i {
            transform: rotate(0);
          }
        }
      }
    }
  }
}

/* Accordian */
form {
  @media (max-width: 767px) {
    text-align: left;
  }
}

// .pagination css
.custom-item {
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
  background-color: #180139;
  color: #fff;
  @include trans1();

  &:hover {
    background-color: $color;
    border-color: $color;
  }
}

.custom-item--active {
  background-color: $color;
  border-radius: 50px;
  border-color: $color;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.custom-item--disable {
  opacity: 0.5;
}

.custom-root ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-go-item {
  align-items: center;
  display: flex;
  font-size: 1rem;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  width: 150px;
}

.custom-go-item input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 40px;
  width: 100%;
}

.custom-progress-bar {
  background-color: #9e75ff;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
  height: 3px;
  margin: 1rem 0;
  max-width: 100%;
  transition: width 0.2s ease;
}

table {
  border-spacing: 8px;
  /* add 8 pixels of space between cells */
}

table td,
table th {
  padding: 8px;
  /* add 8 pixels of padding to each cell */
}

.my-dropdown-menu {
  width: 200px;
  /* or any other width you want */
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #9e75ff;
  content: "/";
}

form-select::before {
  color: #9e75ff;
  /* change the color to whatever you want */
}

.button-input-container {
  position: relative;
  width: 100%;

  button {
    position: absolute;
    right: 10px;
    top: 12px;
    background-color: transparent !important;
    border: none !important;
    color: $black;
    padding: 0;
    z-index: 2;

    &:focus,
    &:hover {
      color: $color;
    }
  }

  input {
    padding-right: 25px;
  }
}

input[type="file"] {
  text-align: center;
  line-height: 20px;
  font-size: 15px !important;
}

input::file-selector-button {
  font-weight: bold;
  text-align: center;
  color: $black;
  font-size: 15px;
  padding: 0;
  border: none;
  border-radius: 3px;
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

select {
  background-size: 50px 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../src/assets/images/icons/dropdown.png);
  background-repeat: no-repeat;
  background-position: 99.2% center;
  background-size: 30px 30px;
  /* customize the icon size as needed */
  padding-right: 30px !important;
}

.modal-content {
  border-color: $color;

  .h4,
  .modal-title {
    color: $color;
    font-weight: 700;
  }

  .modal-header {
    border-color: $color;
    position: relative;
    padding-right: 20px;
  }

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    padding: 0;
    color: $black;
    background-color: transparent !important;
    border: none !important;

    &:hover {
      color: $color;
    }
  }

  .modal-footer {
    border-color: $color;
  }
}

.time-pnl {
  margin: 0;
  font-size: 14px;
  color: $black;
  overflow: hidden;
  height: 21px;

  p {
    margin: 0;
    font-size: 14px;
    color: $black;
    overflow: hidden;
    height: 21px;
  }
}

.icon-ul {
  display: flex;
  justify-content: normal;
  list-style-type: none;
  cursor: pointer;
}

.icon-ul li {
  margin-right: 10px;
}

.icon-ul li .fa {
  font-size: 15px;

  color: #9e75ff;
}

.font-bold {
  color: #000000 !important;
}

input[type="date"] {
  border-color: transparent;
  /* Additional styles can be added here */
}

input[type="date"]:focus {
  outline: none;
  /* Remove the default focus outline */
}

.nft-image-p {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container {
  max-width: 400;
  height: 150;
}

.reset-icon {
  height: 18px;
  width: 18px;
  margin-right: 3px;
}

.reset-container {
  background: white;
  color: #9e75ff;
  border: 1px solid #9e75ff;
  cursor: pointer;
}
.error-login {
  color: red !important;
}
.heading-min-height {
  min-height: 100px;
  padding-top: 5px;
}

.price-min-height {
  height: 24px;
  overflow: hidden;
}

.search-wrapper {
  position: relative;
}

.search-wrapper .reg-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.parentdiv {
  width: 100%;
  height: 100%;
}

#para {
  color: grey;
  font-size: 13px;
}

#loading-span {
  background-color: #ffffff !important;
}
//  .Loader-image-s{
//    position: fixed;left: 45%;top: 45%;
//  }
// .Loader-image {
//   margin-left: 130%
// }

// .Loader-image-p {
//   margin-left: 100%
// }

// .Loader-image-s {
//   margin-left: 40%
// }

// .Loader-image-subadmin {
//   margin-left: 110%
// }

.imgindiv {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Min-Prize {
  min-height: 20px !important;
}

.block {
  display: block;
}
.history-table {
  max-height: 400px;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}
.loader-style {
  min-height: 50px;
  display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
}
.history-table td {
  min-width: 150px;
}
.save-btn {
  background: #ad79f7;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
}
.cross-modal {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: 8px;
}
.table-td {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
  // background-color: green;
}

.image-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: orange;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}
.red-heart {
  color: red;
  //  cursor:pointer ;
}
.table-span {
  // margin-left: 20px;
  width: 50%;
}

.heart-count {
  padding-left: px;
}
.collection-post h4 {
  height: 47.5px; /* Adjust according to the font size and line height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Clamp to 2 lines */
  -webkit-box-orient: vertical; /* Set the box orientation to vertical */
  text-overflow: ellipsis; /* Show ellipsis when the text overflows */
  line-height: 1.2; /* Adjust the line height as needed */
  max-height: calc(1.2em * 2); /* Ensure the height doesn't exceed 2 lines */
}

.breadcrumb-item {
  max-width: 550px; /* Adjust this width based on your layout */
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
  overflow: hidden; /* Hide the overflow text */
  text-overflow: ellipsis; /* Display the ellipsis when text overflows */
  display: inline-block; /* Ensure it behaves like an inline element */
}

.collection-post p {
  height: 21px;
  overflow: hidden;
}

.collection-post .txt-pnl {
  height: 150px;
}

.css-13cymwt-control,
.basic-single,
.css-qbdosj-Input,
.css-1hb7zxy-IndicatorsContainer,
.css-qbdosj-Input {
  height: 60px !important;
  padding: 0 !important;
}

.css-1hb7zxy-IndicatorsContainer {
  // height: 50px !important;
}

.custom-circle {
  position: absolute;
  left: 8px;
  top: 15px;
  z-index: 2;
  background-color: #f3ebff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f3ebff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.propertiesDropDown {
  border-right: 1px solid #c7a7a7b9 !important;
  border-left: 1px solid #c7a7a7b9 !important;
  height: 40px !important; /* Specify the unit for height, for example, pixels (px) */
  color: black !important;
  /* Remove the commented lines or uncomment them if needed */
  /* borderRight: 1px solid #c7a7a7b9; */
  /* borderLeft: 1px solid #c7a7a7b9; */
  /* height: 40px; */
  /* color: black; */
}
.PropertiesDetail {
  background-color: rgba(21, 178, 229, 0.06) !important;
  border-radius: 6px !important;
  border: 1px solid #ad79f7 !important;
  padding: 5px !important;
  text-align: center !important;
  word-break: break-word !important;
  background: #ad79f7 !important;
  color: #fff !important;
  margin-bottom: 10px !important;
}
.multi-select {
  width: 100% !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  &:focus {
    outline: 1px solid var(--primary_color) !important;
  }
}

.css-48ayfv {
  width: 100% !important;
  height: 48px !important;
}

.css-1r4vtzz {
  font-size: 50px;
  width: -webkit-fill-available !important;
  height: 48px !important;
}

.multi-select .dropdown-indicator {
  color: #555;
}

.multi-select .option {
  padding: 8px;
  width: -webkit-fill-available !important;
}

.multi-select .option.is-selected {
  background-color: #007bff;
  color: #fff;
}

.multi-select .option input[type="checkbox"] {
  margin-right: 8px;
}

.multi-select .menu {
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px";
}

.multi-select .menu .menu-list {
  padding: 0;
}

.multi-select .menu .menu-list .menu-item {
  padding: 8px;
}

.multi-select .menu .menu-list .menu-item:hover {
  background-color: #f0f0f0;
}

.plain-dropdown .multi-select .search-bar {
  border-color: 1px solid rgb(4, 117, 4) !important;
}
.Multifield {
  box-shadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px";
}
.css-1gpjby2 svg {
  display: none;
  // width: 90px !important;
  // height: 50px !important;
  // margin-left: 80px !important;
  // padding: 30px 0px !important;
}
.plain-dropdown .multi-select .search-bar :focus {
  border-color: 1px solid rgb(4, 117, 4) !important;
}
.css-6y1x9t-control {
  // outline: 1px solid var(--primary_color) !important;

  border-color: 1px solid var(--primary_color) !important;
  box-shadow: 0 0 0 1px var(--primary_color) !important;
  border-width: 0px !important;
}
.css-1pcexqc-container {
  // position: absolute !important;;
}
/// bannerr css styles
///
// img{max-width: 100%;}

// $white:#ffffff;
// $black:#131313;
$box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
// h2{
//   font-size: 24px;
//   color: $black;
//   font-weight: 700;
// }

// // Form
// label,
// .form-label{
//   font-size: 16px;
//   color: $black;
//   font-weight: 600;
// }

body,
html,
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
img {
  max-width: 100%;
}

$white: #ffffff;
$black: #131313;
$box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
h2 {
  font-size: 24px;
  color: $black;
  font-weight: 700;
}

// Form
label,
.form-label {
  font-size: 16px;
  color: $black;
  font-weight: 600;
}
.custom-file-input {
  position: relative;
  width: 100%;
  border-radius: 4px;
  box-shadow: $box-shadow;
  .file-input-heading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      max-width: 20px;
      margin-right: 5px;
    }
  }
  input {
    opacity: 0;
  }
}
.form-select {
  box-shadow: $box-shadow;
  border-color: transparent;
}
.form-check-input,
.form-select,
select,
.form-control,
input {
  &:focus {
    box-shadow: none;
    border-color: #ac7af7;
  }
}
.form-check-input:checked,
input[type="checkbox"]:checked,
input[type="checkbox"]:enabled:checked {
  background-color: #ac7af7 !important;
  color: white;
  border-color: #ac7af7;
}
// .slider-picker > div:last-child{
//   display: none;
// }
// .reg-btn{
//   display: inline-block;
//   width: 100%;
//   background: linear-gradient(to right,  #ac7af7 0%,#9458ef 100%);
//   line-height: 40px;
//   color: $white;
//   box-shadow: $box-shadow;
//   border: none;
//   border-radius: 8px;
//   font-size: 18px;
//   font-weight: 500;

// }
.banner-generator-main {
  background-color: #fafafa;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 50px 50px;
  min-height: 100vh;
  align-items: flex-start;
}
.banner-generator-main .form-pnl {
  width: 400px;
  padding: 30px 30px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  background-color: white;
  border-radius: 30px;
  min-height: 400px;
  box-sizing: border-box;
}
.banner-generator-main .banner-pnl {
  width: calc(100% - 430px);
  padding: 40px 50px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  background-color: white;
  border-radius: 30px;
  box-sizing: border-box;
}
.btn-prev-next {
  background: linear-gradient(to right, #ac7af7 0%, #9458ef 100%);
  color: $white;
  box-shadow: $box-shadow;
  border: none;
  border-radius: 10px;
  font-size: 18px;
}

/* Greenline Mobile styles */
.banner-content-landscape {
  position: absolute;
  display: flex;
  align-items: center;
  // justify-content: center;
}
.live-title-landscape {
  position: relative;
  top: -25%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: bold;
  z-index: 10;
  white-space: nowrap;  
  overflow: hidden;    
  text-overflow: ellipsis; 
}

.Landscape-productContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Landscape-imgPriceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20%;
}

.Landscape-transparent-image {
  max-width: 50%;
  order: 2;
}

.Landscape-priceTag {
  order: 1;
  margin-left: -25%;
}

.Landscape-nameTag {
  margin-top: -35px; /* Space between the image and the name */
  text-align: center;
}

.footer-style-landscape-modal {
  position: absolute;
  bottom: 6%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.T-and-C-landscape-modal {
  position: absolute;
  bottom: 6%;
  left: 3%;
  font-size: 15.8px;
  text-align: center;
}
.footer-style-landscape-live {
  position: absolute;
  bottom: -44%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.T-and-C-landscape-live {
  position: absolute;
  bottom: -44%;
  left: -15%;
  font-size: 15.8px;
  text-align: center;
}

/* Menu Board TV Portrait styles */
.Mobile .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.live-title-mobile {
  position: absolute;
  top: 2%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  color: "titleColor";
  font-size: "titleFontSize";
  text-align: center;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.Mobile-imgcont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-right: 100px;
  order: 2;
}

.Mobile .transparent-image {
  max-width: 45%;
  order: 2;
}

.Mobile .priceTag {
  order: 3;
}

.Mobile .nameTag {
  order: 1;
  font-weight: bold;
}

/* Greenline Desktop styles */

.Desktop-imgcont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  gap: 40%;
  margin-top: 20px;
}

.Desktop-transparent-image {
  max-width: 20%;
  margin-top: 40px;
  order: 2;
}

.Desktop-priceTag {
  font-size: 50px;
  margin-left: 10px;
  order: 3;
}

.Desktop-nameTag {
  order: 1;
  display: inline-block;
  max-width: 70%;
  max-height: 250px;
  width: 100%;
  white-space: normal; /* Allows wrapping to new lines */
  word-wrap: break-word; /* Wraps the text properly */
  line-height: 1.1;
  // overflow: hidden; /* Prevents overflow */
}

// A4 Size

.banner-content-a4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}

.live-title-a4 {
  text-align: center;
  font-weight: bold;
  z-index: 10;
  margin-top: 80px;
  width: 100%;
}

.a4-productContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.a4-nameTag {
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block; /* Ensure the name fits the underline */
  text-align: center;
  white-space: nowrap; /* Prevent the text from wrapping */
}

.a4-nameTag::after {
  content: "";
  display: block;
  width: 100%; /* Match exactly the width of the text */
  height: 3px; /* Thickness of the underline */
  background-color: currentColor; /* Use the text color */
  margin-top: -7px; /* Space between the text and the underline */
  position: relative;
  top: 4px; /* Adjust the underline position */
}

.a4-nameTag-Modal {
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block; /* Ensure the name fits the underline */
  text-align: center;
  white-space: nowrap; /* Prevent the text from wrapping */
}
.a4-nameTag-Modal::after {
  content: "";
  display: block;
  width: 100%; /* Match exactly the width of the text */
  height: 3px; /* Thickness of the underline */
  background-color: currentColor; /* Use the text color */
  margin-top: -7px; /* Space between the text and the underline */
  position: relative;
  top: 4px; /* Adjust the underline position */
}

.a4-description {
  font-size: 20px;
  font-size: 17px;
  color: #000000;
  max-width: 90%;
  margin-left: 5%;
  text-align: center;
  word-wrap: break-word; /* Breaks the word if it exceeds the width */
  word-break: break-word; /* Ensures long words break onto the next line */
  overflow-wrap: break-word; /* Ensures compatibility across browsers */
  white-space: normal; /* Allows text to wrap onto the next line */
  margin-top: 80px; /* Optional: Add some space between lines */
}

.a4-priceTag {
  position: absolute;
  font-size: 23px;
  color: white;
  top: 52%;
  left: 37.5%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.regularprice {
  font-size: 33px;
  color: white;
}

.a4-transparent-image {
  max-width: 40%;
  position: absolute;
  right: 0;
  bottom: -22%;
}

.footer-style-a4 {
  position: absolute;
  bottom: -40%;
  margin-left: 10px;
  max-width: calc(100% - 20px);
  text-align: center;
  transform: none;
  white-space: pre-wrap; /* Allows \n to create new lines */
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid black;
  padding: 5px;
  box-sizing: border-box;
}



// price cut

.price-wrapper {
  position: relative;
  display: inline-block;
}

.price-slash {
  position: relative;
  width: 100%;
  height: 0;
  border-top: 5px solid black;
  transform: rotate(-16deg);
  top: 40px;
}

.price {
  font-size: 30px;
}

// A4New

.banner-content-a44 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}

.live-title-a44 {
  text-align: center;
  font-weight: bold;
  z-index: 10;
  margin-top: 80px;
  width: 100%;
}

.a44-productContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.a44-nameTag {
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block; /* Ensure the name fits the underline */
  text-align: center;
  white-space: nowrap; /* Prevent the text from wrapping */
}

.a44-nameTag::after {
  content: "";
  display: block;
  width: 100%; /* Match exactly the width of the text */
  height: 3px; /* Thickness of the underline */
  background-color: currentColor; /* Use the text color */
  margin-top: -7px; /* Space between the text and the underline */
  position: relative;
  top: 4px; /* Adjust the underline position */
}

.a44-nameTag-Modal {
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block; /* Ensure the name fits the underline */
  text-align: center;
  white-space: nowrap; /* Prevent the text from wrapping */
}

.a44-nameTag-Modal::after {
  content: "";
  display: block;
  width: 100%; /* Match exactly the width of the text */
  height: 3px; /* Thickness of the underline */
  background-color: currentColor; /* Use the text color */
  margin-top: -7px; /* Space between the text and the underline */
  position: relative;
  top: 4px; /* Adjust the underline position */
}

.a44-description {
  font-size: 17px;
  color: #000000;
  max-width: 90%;
  font-weight: 200;
  margin-left: 5%;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  margin-top: 50px;
}
.a44-priceTag-modal {
  position: absolute;
  font-size: 23px;
  color: #000000;
  top: 52.5%;
  left: 55%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.a44-priceTag {
  position: absolute;
  font-size: 23px;
  color: #000000;
  top: 53%;
  left: 33%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.regularprice {
  font-size: 33px;
  color: white;
}

.a44-transparent-image {
  max-width: 45%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer-style-a44 {
  position: absolute;
  bottom: -30%; /* Align to the bottom of the banner */
  right: 0; /* Align to the right side */
  margin-right: 10px;
  max-width: calc(
    100% - 20px
  ); /* Ensure it stays within the banner's borders */
  text-align: center; /* Right align text inside the footer */
  transform: none; /* Remove translation */
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide overflow if content is too wide */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  border: 1px solid black; /* Add solid black border */
  padding: 5px; /* Add some padding inside the border */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  border-radius: 14px;
}

/* { name: 'Menu Board TV Portrait', dimensions: { width: 1080, height: 1920 } } */

.live-title-portrait {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2; /* Ensures it appears above the background */
}

.Portrait-imgcont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.Portrait-transparent-image {
  max-width: 60%;
}

.Portrait-priceTag {
  font-size: 60px;
  margin-top: -10px;
}

.Portrait-nameTag {
  font-weight: bold;
}
.footer-style-Portrait {
  position: absolute;
  bottom: 24%;
  max-width: 100%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.T-and-C-Portrait {
  position: absolute;
  bottom: 30%;
  left: 50%;
  font-size: 11.8px;
  text-align: center;
}

// mobile nft
.live-title-Mobile-NFT {
  position: absolute;
  top: 2%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  justify-content: center;
  z-index: 1;
}
.product-details-NFT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10%;
}

.footer-style-NFT {
  position: absolute;
  bottom: 6%;
  left: 45%;
  max-width: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.T-and-C-NFT {
  position: absolute;
  bottom: 6%;
  left: 3%;
  font-size: 8.8px;
  text-align: center;
}
.QR-Generator {
  height: 20%;
  width: 20%;
  position: absolute;
  bottom: 25%;
  left: 78%;
}

.NFT-transparent-image {
  max-width: 45%;
  margin-top: -15px;
  margin-bottom: -5px;
  order: 1;
}


.NFT-Name {
  position: relative;
  order: 2;
  top: 50%;
  // left: 40%;
  max-width: 100%;
  transform: translateX(-50%,-50%);
  text-align: center;
  justify-content: center;
  z-index: 1;
}
.NFT-Price {
  position: relative;
  order: 3;
  margin-top: 15px;
}

// live preview stylings

.live-bnr-nft {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // border-radius: 10px;
}

.live-bnr {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // border-radius: 10px;
}
.live-title {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
  color: "titleColor";
  font-size: "titleFontSize";
  text-align: center;
  font-weight: bold;
  font-family: Arial, sans-serif;
}
.loading-styling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  color: "titleColor";
  font-family: "titleFontFamily";
}
.loading-styling-pdf {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  color: "titleColor";
  font-family: "titleFontFamily";
}
.product-details {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-img {
  height: 28%;
  width: 28%;
  align-items: center;
  justify-content: center;
}
.product-name {
  word-wrap: break-word; /* Allows breaking words at the end of a line */
  overflow-wrap: break-word; /* Ensures long words break and wrap to the next line */
  max-width: 100%;
  margin: 0;
  font-size: "nameFontSize";
}
.footer-style {
  position: absolute;
  bottom: 1%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.T-and-C {
  position: absolute;
  bottom: 1%;
  left: 20px;
  color: "titleColor";
  font-size: 20px;
  font-family: "titleFontFamily";
}
/* Banner preview styling */
.banner-preview {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollable-form-container {
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.scrollable-form-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.scrollable-form-container-live {
  height: 1232px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.scrollable-form-container-live-pdf {
  height: 1300px;
  overflow-y: auto;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.colorBorder {
  border: 1px solid #ced4da;
}
.button-border {
  display: inline-block;
  width: 100%;
  background: linear-gradient(to right, #ac7af7 0%, #9458ef 100%);
  line-height: 22px;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
}

/* styles.css */
.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 12%;
}
/* styles.css */
.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  color: var(--title-color); /* Use CSS variables if needed */
  font-family: var(--title-font-family); /* Use CSS variables if needed */
}

.banner-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.banner-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-image {
  max-width: 35%;
}

.product-detail {
  margin-bottom: 10px;
}

.footer-style {
  margin-top: 20px;
}

.terms-and-conditions {
  margin-top: 10px;
}

.banner-header {
  margin: 15px;
}
.banner-header-pdf {
  margin: 45px;
}

// model stylings

.footer-style-model {
  position: absolute;
  bottom: -22%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.T-and-C-model {
  position: absolute;
  bottom: -22%;
  left: -47%;
  font-size: 20px;
}
.title-model {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
}
.modal-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.banner-background-modal {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Modal.Body {
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important ;
}
.modal-dialog {
  margin: 0 auto;
}
.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-div {
  padding: 20px;
}

// Modall Stylings according to screens

// GreenLine Mobile
.modelContainerGreenline_Mobile .titlemodel {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
}
.modelContainer.Greenline_Mobile .product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15%;
}
.modelContainer.Greenline_Mobile .img-modal {
  height: 25%;
  width: 25%;
  order: 1;
  margin-top: -50px;
}
.modelContainer.Greenline_Mobile .price-modal {
  order: 3;
}
.modelContainer.Greenline_Mobile .name-modal {
  order: 2;
}

.modelContainer.Greenline_Mobile .footer-stylemodel {
  position: absolute;
  bottom: 0%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.modelContainer.Greenline_Mobile .T-and-C-model {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  text-align: center;
}

// a4 Size

.modelContainer.A4_Size .titlemodel {
  text-align: center;
  font-weight: bold;
  z-index: 10;
  margin-top: 80px;
  margin-bottom: 20px;
  width: 100%;
}
.modelContainer.A4_Size .product-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}
.modelContainer.A4_Size .a4-transparent-image {
  max-width: 40%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.modelContainer.A4_Size .a4-priceTag {
  position: absolute;
  color: white;
  font-weight: bold;
  top: 52%;
  font-size: 25px;
  left: 57%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.modelContainer.A4_Size .name-modal {
  text-align: center;
  font-weight: bold;
  margin-top: -100px;
  z-index: 10;
  width: 100%;
}
.modelContainer.A4_Size .price-container {
  position: relative;
  display: inline-block;
}

.modelContainer.A4_Size .a4-description {
  font-size: 20px;
  max-width: 90%;
  margin-left: 5%;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  margin-top: 80px;
}

.modelContainer.A4_Size .footer-stylemodel {
  position: absolute;
  left: 2;
  bottom: 2%;
  max-width: calc(100% - 20px);
  text-align: center;
  transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid black;
  padding: 5px;
  box-sizing: border-box;
  width: 60%;
  word-wrap: break-word; /* Allows breaking of long words */
  overflow-wrap: break-word; /* Ensures compatibility */
  white-space: pre-wrap; /* Preserves whitespace and allows wrapping */
  max-width: 100ch; /* Limit the line length to 100 characters */
}

// New A4

.modelContainer.A4Size .titlemodel {
  text-align: center;
  font-weight: bold;
  z-index: 10;
  margin-top: 80px;
  width: 100%;
}
.modelContainer.A4Size .product-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}
.modelContainer.A4Size .a44-transparent-image {
  max-width: 45%;
  position: absolute;
  right: 0;
  bottom: 9%;
}
.modelContainer.A4Size .a44-priceTag {
  position: absolute;
  top: 200px; /* Adjust this value as needed */
  left: 230px; /* Adjust this value as needed */
  transform: translate(-50%, -50%);
}

.modelContainer.A4Size .name-modal {
  text-align: center;
  font-weight: bold;
  margin-top: -100px;
  z-index: 10;
  width: 100%;
}
// .modelContainer.A4Size .price-container{
//   position: relative;
//   text-align: center;
// }

.modelContainer.A4Size .a44-description {
  font-size: 20px;
  max-width: 90%;
  margin-left: 5%;
  font-weight: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  margin-top: 50px;
}
.modal-content-live {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
}

.modelContainer.A4Size .footer-stylemodel-a4 {
  color: #a9d18e;
  position: absolute;
  bottom: 1%; /* Align to the bottom of the banner */
  right: 0;
  margin-right: 10px;
  max-width: calc(
    100% - 20px
  );
  border-radius: 14px;
  text-align: center; /* Right align text inside the footer */
  transform: none; /* Remove translation */
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide overflow if content is too wide */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  border: 1px solid black; /* Add solid black border */
  padding: 5px; /* Add some padding inside the border */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  width: 60%; /* Ensure the div has a defined width */
  word-wrap: break-word; /* Allows breaking of long words */
  overflow-wrap: break-word; /* Ensures compatibility */
  white-space: pre-wrap; /* Preserves whitespace and allows wrapping */
  max-width: 100ch; /* Limit the line length to 100 characters */

}

// GreenLine_Desktop
.modelContainerGreenline_Desktop .titlemodel {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
}
.modelContainer.Greenline_Desktop .product-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
.modelContainer.Greenline_Desktop .img-modal {
  height: 10%;
  width: 10%;
  order: 2;
}
.modelContainer.Greenline_Desktop .price-modal {
  order: 3;
  margin-left: 400px;
}
.modelContainer.Greenline_Desktop .name-modal {
  order: 1;
}

.modelContainer.Greenline_Desktop .footer-stylemodel {
  position: absolute;
  bottom: 0%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.modelContainer.Greenline_Desktop .T-and-C-model {
  position: absolute;
  bottom: 5px;
  left: 20px;
  font-size: 20px;
}

// Landscape

.modelContainer.Menu_Board_TV_Landscape .titlemodel {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
}
.modelContainer.Menu_Board_TV_Landscape .product-details-Potrait {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
.modelContainer.Menu_Board_TV_Landscape .img-modal {
  //  height: 25%;
  //  width: 25%;
  order: 2;
  max-width: 60%;
}
.modelContainer.Menu_Board_TV_Landscape .price-modal {
  order: 3;
  margin-right: 10%;
}
.modelContainer.Menu_Board_TV_Landscape .name-modal-Landscape {
  order: 1;
  max-width: 100%;
  text-align: center;
  margin-top: 10px;
}

.modelContainer.Menu_Board_TV_Landscape .footer-stylemodel {
  position: absolute;
  bottom: 0%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.modelContainer.Menu_Board_TV_Landscape .T-and-C-model {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 20px;
}

// portrait

.modelContainer.PORTRAIT_SIZE .titlemodel {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  justify-content: center;
  z-index: 1;
}
.modelContainer.PORTRAIT_SIZE .product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
.modelContainer.PORTRAIT_SIZE .img-modal {
  height: 50%;
  width: 50%;
  order: 1;
}
.modelContainer.PORTRAIT_SIZE .price-modal {
  order: 3;
  margin-top: -20px;
}
.modelContainer.PORTRAIT_SIZE .name-modal {
  order: 2;
  margin-top: -50px;
}

.modelContainer.PORTRAIT_SIZE .footer-stylemodel {
  position: absolute;
  bottom: 28%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}

.modelContainer.PORTRAIT_SIZE .T-and-C-model {
  position: absolute;
  bottom: 30.5%;
  left: 50%;
  font-size: 13.8px;
  text-align: center;
}

/* Mobile NFT */
.modelContainer.Mobile_NFT .titlemodel {
  position: absolute;
  top: 2%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  justify-content: center;
  z-index: 1;
}
.modelContainer.Mobile_NFT .product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
.modelContainer.Mobile_NFT .img-modal {
  height: 55%;
  width: 55%;
  //  margin-top: 20px;
  order: 1;
}
.modelContainer.Mobile_NFT .price-modal {
  order: 3;
  margin-bottom: -10px;
}
.modelContainer.Mobile_NFT .name-modal {
  order: 2;
  text-align: center;
}

.modelContainer.Mobile_NFT .footer-stylemodel {
  position: absolute;
  bottom: 20%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}

.modelContainer.Mobile_NFT .T-and-C-model {
  position: absolute;
  bottom: 24%;
  left: 50%;
  font-size: 13.8px;
  text-align: center;
}

// Animation stylings

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 5, 5, 0.884);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.modal-overlay.show {
  opacity: 1;
}

.modal-overlay.hide {
  opacity: 0;
}

.modal-content-live {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%) !important  ;
  background-repeat: no-repeat;
  justify-content: space-between;
  background: white;
  padding: 20px;
  max-width: 90%;
  width: 100%;
  max-height: 90%;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.modal-content-live.fade-in {
  opacity: 1;
  transform: scale(1);
}

.modal-content-live.fade-out {
  opacity: 0;
  transform: scale(0.95);
}
// Qr generator

.gradient-text {
  background-image: linear-gradient(90deg, #00ff00, #0000ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 87%;
}

.buttons-container .reg-btn {
  width: 49%;
}
.buttons-container-pdf {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  width: 87%;
}

.buttons-container-pdf .reg-btn {
  width: 49%;
}
.custom-tabs {
  display: flex;
  justify-content: center;
}

.custom-tabs .nav-link.active {
  background-color: rgba(173, 121, 247, 0.6) !important;
  color: $white;
  box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6) !important;
  border-color: $color !important;
}
.custom-tab {
  display: flex;
  margin-left: 10px;
}

.custom-tab .nav-link.active {
  background-color: rgba(173, 121, 247, 0.6) !important;
  color: $white;
  box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6) !important;
  border-color: $color !important;
}

.Discription_ {
  margin-top: 20px;
}

.banner-preview .sold-out-banner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it appears on top */
}

.RegularPrice-pdf {
  font-size: 55px;
}
.Formated_Price_pdf {
  font-size: 55px;
}
.sold-out-wrapper {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.msgSVG {
  margin-left: 60px;
}

.StarSvgLive {
  margin-top: 30px;
}
.white {
  color: white !important;
}

.hidden-banner {
  visibility: hidden;
  height: 0;
}
.Downloadallbtn {
  background: linear-gradient(to right, #ac7af7 0%, #9458ef 100%);
  color: white;
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 10px;
  font-size: 20px;
  position: relative;
  left: 83%;
  top: 40px; /* Adjust the value to control vertical position */
}
.DownloadBannerbtn {
  margin-top: 7px;
  background: linear-gradient(to right, #ac7af7 0%, #9458ef 100%);
  color: white;
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 10px;
  font-size: 25px;
  width: 100%;
}
.neon {
  color: #fff;
  text-shadow: 0 0 5px #0ff, 0 0 10px #0ff, 0 0 20px #0ff, 0 0 40px #0ff;
}

/* Glitch Effect */
.glitch {
  position: relative;
  color: #f00;
}
.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -2px 0 blue;
  top: 0;
  color: #00f;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: glitch-anim 2s infinite linear alternate-reverse;
}
@keyframes glitch-anim {
  0% {
    clip: rect(42px, 9999px, 44px, 0);
    transform: skew(0.2deg);
  }
  100% {
    clip: rect(12px, 9999px, 56px, 0);
    transform: skew(0.3deg);
  }
}

/* Echo Effect */
.echo {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4), 4px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Splice Effect */
.splice {
  color: #fff;
  text-shadow: 4px 4px 0px #ff3f3f, -2px -2px 0px #00f;
}

/* Outline Effect */
.outline {
  -webkit-text-stroke: 1px black;
  color: transparent;
}
// gradient
.gradient {
  background: linear-gradient(90deg, #ff7a18, #af002d, #319197);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* Hollow Effect */
.hollow {
  font-weight: 900;
  -webkit-text-stroke: 1px black;
  color: transparent;
}

/* Shadow Effect */
.shadow {
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
}

/* Lift Effect */
.lift {
  color: #fff;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}
.lift:hover {
  transform: translateY(-2px);
}

/* Emboss Effect */
.emboss {
  color: #fff;
  text-shadow:
    1px 1px 0 #000,
    2px 2px 0 #fff,
    3px 3px 0 #000;
} 

/* Metallic Effect */
.metallic {
  background: linear-gradient(135deg, #8a8a8a, #c0c0c0);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3), -1px -1px 2px rgba(255, 255, 255, 0.7);
}

/* Glow Effect */
.glow {
  color: #fff;
  text-shadow: 0 0 5px #00ff00, 0 0 10px #00ff00, 0 0 15px #00ff00, 0 0 20px #00ff00;
}

/* Engrave Effect */
.engrave {
  color: #000;
  text-shadow: 
    1px 1px 0 #fff,
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000;
}

/* Frosted Effect */
.frosted {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  padding: 10px;
  border-radius: 5px;
}


/* Dropdown grid styles */
.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
  padding: 10px; 
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px; 
  max-height: 200px; 
  overflow-y: auto; 
}

/* Dropdown option styles */
.dropdown-option {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  cursor: pointer;
  padding: 5px; 
  text-align: center; 
}

.effect-image {
  width: 100px; 
  height: 80px; 
  border: 1.5px solid #ccc;
  border-radius: 8px;
  margin-bottom: 5px; 
}

.selected-effect {
  display: flex;
  align-items: center;
}

.selected-effect .effect-image {
  width: 20px;
  height: auto;
  margin-right: 5px; 
}

.selected-effect span {
  font-weight: bold;
}

.dropdown-option:hover {
  background-color: #f0f0f0; 
}

.SelectEffect{
  cursor: pointer;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #ccc;
  background-color :#dddddd;
}
.color-picker-toggle {
  cursor: pointer;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color :#dddddd;
  color: #000000;
  font-weight: bold;
  text-align: left;
  transition: background-color 0.3s;
}

.color-picker-toggle:hover {
  background-color: #e0e0e0;
}

.custom-select{
  cursor: pointer;
  padding: 7px;
  justify-content: center;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color :#dddddd;
  color: #000000;
  font-weight: bold;
  text-align: left;
  transition: background-color 0.3s;
}

.view-title{
  color: "#AD79F7";
  font-size: "16px";
  font-weight: "700";
  margin-left: "5px",
}

.no-min-height {
  min-height: unset !important; /* Removes the min-height */
}


.image-container
{
height: "200px";
 width: "200px" ;
object-fit: contain;}

.Colorbtn {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 10px;
  height: 50px;
}
.colormapbtn{
background : linear-gradient(to right, #ac7af7 0%, #9458ef 100%);
color: white;
padding: 10px 20px;
font-size: 18px;
width: 50%;
border-radius: 5px;
}
.ThreeDotimg
{
  cursor: pointer;
  margin-left: 300px;
}
.dropdown .dropdown-menu{
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.288);

}
.Updatebtn{
  font-size: 14px !important;
  text-align: center;
  border-radius: 5px;
  margin-top: 5px !important;
  border: 2px solid #9458ef ;
  background :#ffffff;
}
.Default_settingsbtn{
  color: #ffffff !important;
  border-radius: 5px;
  
  font-size: 16px;
  height: 40px;
  background : linear-gradient(to right, #ac7af7 0%, #9458ef 100%);
  text-align: center;
}
.Default_settingsbtn:hover {
  background: linear-gradient(to right, #9458ef 0%, #ac7af7 100%);
  font-size: 17px;
}

.color-swatch{
  width: 30px;
  height: 30px;
  cursor:pointer;
  border-radius: 5px;
}
.StandardColor{
width: 100%;
height: 100%;
border-radius: 5px;

}
.color-map{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;

}


.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
}

.react-date-range {
  margin: 0 auto; /* Center the date range picker */
}
.norecords{

    text-align: center;
    justify-content:center;
    align-items: center;
    

}
.errorMessage{
  color: red;
  margin-top: 10px;
  text-align: center;
}
.red{
  color: red !important;
}

.heading{
  color: #AD79F7;
  font-size: 16px;
font-weight: 700;
margin-left: 5px;    
}
.sidebar {
  width: 300px;
  transition: width 0.3s;
  position: relative;
}

.sidebar.active {
  width: 80px; 
}

.arrow-container {
  position: absolute;
  left: 5%;
  bottom: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.arrow {
  border: solid #333;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(130deg);
  transition: transform 0.3s;
}

.arrow.collapsed {
  transform: rotate(-45deg);
}


.footer-container {
  width: 100%;
}

.footer-preview {
  max-width: 100%;
  overflow: auto;
  background-color: #f9f9f9; 
}
.gradient-styling {
  border-radius: 8px;
  // padding: 10px;
  height: 120px;
  width: 100px;
}
.Activityhistory{
margin-bottom: 20px !important;
}
.Redemptionimg{
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.img-fluid{
  height: 100%;
  width: 100%;
}
.Edit-icon{
  height: 20px;
  width: 20px;
}
.url-cont{
  display: flex;
  flex-direction: row;
  gap: 100px;
  cursor: pointer;
}
.Input-URl{
border: 1px solid #9458ef;
border-radius: 8px;
height: 35px;
padding: 20px;
width: 70%;
}
.Editicon{
  cursor: pointer;
  color:#9458ef ;
  margin-left: 10px;
}
.OR-SendNFt{
  text-align: center;
  margin-top: 10px;
  margin-bottom: -5px;
}
.error-Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.error-Modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.error-Modal-content h2 {
  margin-bottom: 20px;
}

.error-Modal-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.error-Modal-content th, .error-Modal-content td {
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
}

.error-Modal-content button {
  padding: 10px 20px;
  background-color: #9458ef;
  color: white;
  border: none;
  max-width: 50%;
  border-radius: 10px;
  cursor: pointer;
}

.error-Modal-content button:hover {
  background-color: #9458ef;
}

.hidden {
  display: none !important;
}
.Add-mb-10 {
  margin-bottom: 10px;
}
.custom-tabs-center {
  display: flex;
  justify-content: center; /* Centers the tabs horizontally */
  align-items: center; /* Aligns items vertically */
  gap: 10px; /* Adds spacing between tabs (optional) */
}

.custom-tabs-center .nav-item {
  text-align: center; /* Ensures text is centered inside tabs */
}

.custom-tabs-center .nav-link.active {
  background: linear-gradient(to right, #9458ef 0%, #ac7af7 100%);
  color: white; /* Optional: Adjust text color for better contrast */
  border: none; /* Optional: Remove default border */
  border-radius: 8px; /* Optional: Add rounded corners */
  padding: 10px 15px; /* Optional: Adjust padding */
}
.User-Point-Input{
  width: 20%;
  border-radius: 20px;
}
.input-main-userpoint{
  display: flex;
  flex-direction: row;
}
.width-7px {
  width: 250px;
  background-color: transparent;
  height: 50px;
  font-size: 14px;
  background-color: $white;
  border-color: #9e75ff;
  border-radius: 40px 0 0 40px;
  margin-left: 870px;
  
  &:focus,
  &:hover {
    border-color: #9e75ff;
    box-shadow: 5px 5px 5px rgba(173, 121, 247, 0.6);
  }
}
/* General styling for dropdown menu */
.dropdown .dropdown-menu {
  z-index: 1050; /* Ensures it appears above other elements */
}
.AmmountLogo{
  height: 20px;
  width: 20px;
  margin-left: 10px;  
}
.Date-Range{
  display: flex;
  flex-direction: row;
  margin-left: -200px;
}
.Date-Function{
  display: flex;
  flex-direction: row;
  margin-left: -200px;
}
.Selected-DropDownsection{
  margin-top: 20px;
}
.store-copy-input {
  margin-bottom: 10px;
  position: relative;
}

.store-name {
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  color: #666;
}
.store-copies-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.store-copies-row .form-control {
  width: 150px;
  margin-right: 10px;
}

.store-name {
  color: #666;
}
.available-quantity {
  color: #666;
  font-size: 0.9em;
  margin-left: 8px;
}

.store-copies-container {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.error{
  color:red;
}